// footer
.footer {
	p {
		color: $white!important;
	}
	.footer-sitelinks {
		background-color: $green !important;
	}
	.footer-socket {
		background-color: $green;
		.list {
			display: flex;
			justify-content: center;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
			}
		}
		.list-item {
			margin-right: 1rem;
			a {
				color: #fff !important;
				text-decoration: none;
				font-size: 13px;
			}
		}
	}
	.vocabulary-term-list {
		li {
			margin-right: 1rem;
			font-size: $h3-font-size;
		}

		span {
			display: none !important;
		}
	}
}

// Partner-section
.partner-section {
	padding: 6vh 0;

	.grid-items {
		display: flex;
		flex-flow: row wrap !important;
		max-width: 100vw;
		justify-content: center !important;
		margin: auto !important;

		.item,
		a {
			width: 100% !important;
			max-width: 100px !important;
			min-width: 50px !important;
		}
	}
	.card {
		width: fit-content !important;
		.card-body {
			display: none !important;
		}
	}
}

// bg-*
// =========================================================================
section {
	h1,
	h2 {
		color: $bordeaux;
		font-weight: 300;
	}
	&.bg-light,
	&.bg-dark {
		h1,
		h2 {
			color: $bordeaux !important;
			font-weight: 300;
		}
		p {
			color: $black;
		}
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-two-columns {
		.container-holder {
			.column {
				flex: 0 0 70%;
				&.one {
					max-width: 70%;
				}
				&.two {
					max-width: 30%;
					display: flex;
					justify-content: center;
					align-items: flex-end;
				}

				@include media-breakpoint-down(lg) {
					flex: 0 0 100%;
					&.one,
					&.two {
						max-width: 100%;
						align-items: center;
					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	.youtube-video {
		border-radius: 5px;
		box-shadow: 0 4px 15px 5px #ccc;
		display: flex;
		justify-content: center;
		align-items: center;
		@include media-breakpoint-down(lg) {
			margin-right: 1rem;
			margin-left: 1rem;
		}
	}
	margin: 6vh 0;
	// intro/outro
	.intro,
	.outro {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $bordeaux;
		}
		.container-holder {
			max-width: 75vw;
			text-align: left;
			padding: 6vh 0;
			@include media-breakpoint-down(lg) {
				max-width: unset;
			}
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.content-section:not(.video-section) {
	.container-two-columns {
		.container-holder {
			.column {
				&.two {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}
		}
	}
}

// home-banner
.home-banner,
.content-banner {
	.owl-container {
		height: 50%;
		display: flex;
	}
	.owl-title {
		color: $white !important;
	}
	.owl-subtitle {
		color: $white !important;
		font-family: $font-family-primary !important;
		font-size: $h5-font-size !important;
	}
	.btn {
		margin-top: 1rem;
	}
}
.content-banner {
	.owl-container {
		align-items: center;
	}
	.owl-caption {
		text-align: left !important;
	}
}
.home-banner {
	.owl-title {
		font-size: $h1-font-size !important;
	}
	.owl-container {
		align-items: flex-end;
		justify-content: center;
	}
	position: relative;
}
// scrolldown-section
.scrolldown-section {
	@include media-breakpoint-down(lg) {
		display: none !important;
	}
	background-color: $green-dark;
	z-index: 1000;
	.column {
		display: flex;
		align-items: center;
	}

	.scrolldown-btn {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 4rem;
		&::after {
			content: "\f078";
			font-family: $font-awesome;
			color: $white;
			display: block;
			background-color: $green-dark;
			width: 4rem;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			aspect-ratio: 1/1;
			transform: translateY(-50%);
			z-index: 900;
			cursor: pointer;
		}
	}
}
// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	.owl-container {
		display: none;
	}
	margin: 6vh 0;

	// collection  fix
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 3vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// usp-section
.usp-section {
	background-image: url("/images/blob_green.svg");
	background-position: center top;
	background-repeat: no-repeat;
	padding-bottom: 6vh !important;
	@include media-breakpoint-down(lg) {
		background-size: cover;
	}
}

.usp-section-two {
	background-image: url("/images/blob_red.svg");
	background-position: center top;
	background-repeat: no-repeat;
	padding: 6vh 0 6vh 0;
	display: flex;
	align-items: center;
	@include media-breakpoint-down(lg) {
		background-size: cover;
	}
}

.video-section {
	background-image: url("/images/blob_green_1.svg");
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;
}

.info {
	.subtitle h5 {
		font-weight: 300 !important;
		font-family: $font-family-primary;
	}
}

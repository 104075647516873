.header {
	z-index: 998;
	position: sticky;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	background: $green;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			transform: scale(0.75);
			transition: 0.35s ease-in-out;
		}
	}

	[class^="container-"] {
		.container-holder {
			> .column {
				@extend .align-items-center;
				max-height: 70px;
				border-bottom: 4px solid #16262e;
				@include media-breakpoint-down(lg) {
					max-height: unset;
					flex-flow: row wrap;
					justify-content: space-between;
					padding: 0;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		i {
			color: $white;
		}
		.navbar-toggler[aria-expanded="true"] {
			.navbar-toggler-icon {
				i {
					&::before {
						content: "\f00d";
						transform: rotate(180deg);
						transition: 0.3s ease-in;
					}
				}
			}
		}
	}

	// logo
	.logo {
		transform: translateY(25%);
		margin: 1rem 10px 10px 2.5rem;

		a {
			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			max-width: 220px;
			margin: 0 0 0 0;
		}
		transition: 0.35s ease-in-out;
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 4;
			width: 100%;
			padding: 1.5rem;
			.nav-link {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid white;
				font-weight: 600;
			}
			.last {
				.nav-link {
					border-bottom: 0 !important;
				}
			}
			.nav-item span {
				color: $white;
				width: 100%;
				display: block;
			}
			.dropdown-toggle {
				color: #fff;
				&::after {
					content: "\2b";
					border: 0 !important;
					font-family: $font-awesome;
					font-size: 24px;
					font-weight: 900;
					line-height: 1rem;
					padding: 0;
				}
			}

			.dropdown-toggle[aria-expanded="true"] {
				border-bottom: 0;
				&::after {
					content: "\f068";
					font-size: 12px;
					transform: rotate(180deg);
					transition: 0.3s ease-in;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.nav-item {
				border-top: 4px solid $green;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 1rem 0 0;
				height: 70px;

				&:hover {
					border-top: 4px solid white;
					transition: 0.2s ease-in;
				}
			}
			.navbar-nav {
				width: 100%;
				justify-content: flex-start;
			}

			a {
				color: $white;
				font-weight: 900;
			}
		}
	}

	.dropdown-menu {
		&.show {
			background-color: $green;
			border: none;
			box-shadow: none;
			border-bottom: 1px solid $white;
			padding: 0.5rem;
			min-width: fit-content !important;
			&::before,
			&::after {
				display: none;
			}
			@include media-breakpoint-down(lg) {
				display: block;
				width: 100%;
				position: relative !important;
				transform: none !important;
				font-size: 16px;
				padding: 0 0 1rem 0;

				.nav-link {
					border-bottom: 0 !important;
					font-weight: 300 !important;
					padding: 0.5rem 0 0 0;
				}
			}
			.nav-item {
				height: auto;
				border: 0;
				text-align: left;
				justify-content: flex-start;
				&:hover {
					border: 0;
					text-decoration: underline;
				}
			}
		}
	}

	.header-btn {
		a {
			margin-right: 1rem;
			background: #aa1803;
			color: #fff !important;
			text-decoration: none;
			display: block;
			border-radius: 4px;
			padding: 0.65rem;
			font-weight: bold;

			&:hover {
				background: #b11d06;
			}
			@include media-breakpoint-down(lg) {
				margin-right: 0.5rem;
				padding: 0.35rem;
				font-size: 12px;
			}
		}
	}

	// site-switcher

	.site-switcher {
		.dropdown-menu {
			border: 0 !important;
		}
		.nav-link {
			border: none !important;
			box-shadow: none !important;
			margin: 0 !important;
		}
		margin-right: 1rem;
		.dropdown-toggle {
			border-radius: 0 !important;
			display: flex !important;
			justify-content: center !important;
			border: 0 !important;
			min-width: 100% !important;
			display: flex;
			align-items: center;
			&::after {
				color: $white;
				top: 50%;
				right: -1rem;
			}
		}
		@include media-breakpoint-down(lg) {
			display: none;
			position: absolute;
			right: 1rem;
			top: 4rem;
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}

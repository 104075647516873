// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		@include media-breakpoint-down(lg) {
				background-color: $green-dark;
			}

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 5px;
			background: #bd613c;
			box-shadow: 6px 6px 0 0 rgba(#16262e, 1);

			color: #fff;

			// below eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	margin-top: -50px;
			// 	padding-left: 30px;
			// 	padding-right: 30px;
			// }

			// above eyecatcher
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 6vh;
			}
			@include media-breakpoint-down(lg) {
					margin-top: -50px;
					padding-left: 30px;
					padding-right: 30px;

			}
		}
	}
}

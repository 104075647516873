// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;


	// btn-primary
	&.btn-primary {
		box-shadow: 3px 3px 0 0 rgba(#16262e, 1);
		background-color: $bordeaux-light;
		&:hover {
		background-color: $bordeaux;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.tommy-zeb-btn:not(.tommy-zeb-change-person-categories) {
	background: #aa1803 !important;
	color: #fff !important;
	text-decoration: none;
	display: block;
	border-radius: 4px;
	padding: 0.65rem;
	font-weight: bold;

	&:hover {
		background: #b11d06;
	}
}

.card {
	background-color: rgba(0, 0, 0, 0);
	border: 0;
	.card-body {
		background-color: transparant;
		text-align: center;
		.card-title {
			color: $bordeaux;
		}
		.card-subtitle {
			font-size: $h6-font-size;
		}
	}
}

.bundle-overview-section {
	.card .card-body {
		text-align: left;
	}
	.card-btn {
		background-color: rgba(0, 0, 0, 0);
		border: 0;
		box-shadow: none;
		color: $bordeaux;
		padding: 0;
		margin: 0;
		&::after {
			content: "\f061";
			font-family: $font-awesome;
			float: right;
			display: block;
			margin-left: 0.55rem;
			transition: 0.3s;
		}
		&:hover {
			&::after {
				transform: translateX(0.25rem);
				transition: 0.3s;
			}
		}
	}
}

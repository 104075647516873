// eyecatcher
.eyecatcher,
.eyecatcher-owl-carousel,
.owl-stage,
.eyecatcher .owl-carousel .item {
	height: 50vh !important;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher-owl-carousel {
	.owl-container {
		display: none;
	}
}

.eyecatcher.large,
.owl-stage,
.eyecatcher.large .owl-carousel .item {
	height: 85vh !important;
	min-height: 540px;
	max-height: 1080px;
	@include media-breakpoint-down(lg) {
		height: 55vh;
	}
	// @include media-breakpoint-up(sm) {
	// 	height: 95vh;
	// }
	.owl-dots {
		display: none;
	}
}

@include media-breakpoint-down(lg) {
	.owl-nav {
		display: none;
	}
}
.eyecatcher-content {
	.owl-item,
	.owl-stage {
		height: fit-content !important;
	}
}

.lead-intro {
	color: #000;
}
